import React, { useState, useContext } from 'react';
import { DBContext } from '../../Controller/context';
import {
  Avatar,
  Button,
  Box,
  Sidebar,
  Nav,
  Stack,
  Text,
} from 'grommet';
import {
  Video,
  Play,
  Edit,
  ContactInfo,
  Logout,
  Clock,
  Help,
  Split,
  StatusInfoSmall,
} from 'grommet-icons';
import { useHistory } from "react-router-dom";

export const SideBar = (props) => {
  const { selectedStudent } = useContext(DBContext);
  const history = useHistory();


  const SidebarHeader = () => (
    <Box align="center" gap="small" direction="row" margin={{ bottom: 'small' }}>
      <Stack alignSelf="start" align="center" anchor="top-right">
        <Avatar src={!!selectedStudent.imageUrl ? selectedStudent.imageUrl : ''} />
        {/* <Box pad="xsmall" background="orange" round responsive={false} /> */}
      </Stack>
      <Text>{`${!!selectedStudent.displayName ? selectedStudent.displayName : ''}`}</Text>
    </Box>
  );

  const SidebarButton = ({ icon, label, route, ...rest }) => (
    <Box pad="small">
      <Button
        gap="medium"
        alignSelf="start"
        plain
        icon={icon}
        label={label}
        onClick={() => history.push(`${route}`)}
        {...rest}
      />
    </Box>
  );

  const MainNavigation = () => (
    <Nav gap="small" responsive={false}>
      <SidebarButton icon={<StatusInfoSmall />} label="Home" route='/Home' />
      <SidebarButton icon={<Video />} label="Live Classes" route='/LiveClass' />
      <SidebarButton icon={<Play />} label="Lessons" route='/Lessons' />
      <SidebarButton icon={<Edit />} label="Homeworks" route='/Homework' />
      <SidebarButton icon={<Split />} label="Exams" route='/Exams' />
      <SidebarButton icon={<Clock />} label="Schedule" route='/Schedule' />
    </Nav>
  );

  const SidebarFooter = () => (
    <Nav gap="xsmall" >
      <SidebarButton icon={<ContactInfo />} label="Profile" />
      <SidebarButton icon={<Help />} label="How to use" />
      <SidebarButton icon={<Logout />} label="Logout" />
    </Nav>
  );


  return (
    <Box height={{ min: '100%' }} width={{ min: '100%' }}>
      <Sidebar
        responsive={true}
        background="neutral-2"
        header={<SidebarHeader />}
        footer={<SidebarFooter />}
        pad={{ left: 'medium', right: 'medium', vertical: 'medium' }}
      >
        <MainNavigation />
      </Sidebar>
    </Box>
  )
}

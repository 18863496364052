import React, { useEffect, useContext, useState } from 'react';
import { Layout } from '../Layout';
import { DBContext } from '../../Controller/context';
import { Spinner, HomeworkFullScreenLayer } from '../Components';
import {
  Box, Heading, Text, Layer,
  List, Button,
} from "grommet";
import { Edit } from 'grommet-icons';
var moment = require('moment');
export const HomeworkPage = () => {

  const {
    fetching,
    homeworks,
    fetchHomeworks,

  } = useContext(DBContext);

  const [isHomeorkLayerOpen, setIsHomeorkLayerOpen] = useState(false);
  const [selectedHomeork, setSelectedHomeork] = useState(false);
  /**
   * Fetches the lessons from the cloud when
   * the page first loaded
   */
  useEffect(() => {
    fetchHomeworks();
  }, []);

  const openHomeworkLayer = (homeworkData) => {
    setSelectedHomeork(homeworkData);
    setIsHomeorkLayerOpen(true);
  }

  const closeHomeworkLayer = () => {
    setIsHomeorkLayerOpen(false);
  }

  const HomeworkTile = (props) => {
    const { homeworkData } = props;
    return (
      <Box
        fill
        key={homeworkData.id}
        direction='row-responsive'
        justify='stretch'
        align='center'
        alignContent='stretch'
        round='true'
        width='100%'

      >
        <Edit size='large' />
        <Box
          direction='column'
          pad='small'
          justify='evenly'
          flex='grow'
        >
          <Text size='large'>{homeworkData.homeworkTitle}</Text>
          <Text size='medium' color='grey'>{homeworkData.homeworkDiscreption}</Text>
          <Box
            direction='row-responsive'
            justify='start'
            align='center'
            gap='large'
            margin='xsmall'
          >
            <Text size='xsmall' color='grey'>{homeworkData.selectedSubject}</Text>
            <Text size='xsmall' color='grey'>{`Due in ${moment(homeworkData.dueDate).fromNow()}`}</Text>
          </Box>


        </Box>
        <Button
          primary
          size='small'
          label='View Homework'
          onClick={() => openHomeworkLayer(homeworkData)}
        />

      </Box>
    )
  }


  return (
    <Layout>
      <Box fill>
        {isHomeorkLayerOpen &&
          <Layer full animation="fadeIn">
            <HomeworkFullScreenLayer
              close={closeHomeworkLayer}
              homeworkData={selectedHomeork}
            />
          </Layer>
        }

        <Box
          direction='column'
          align='start'
          basis='full'
          margin={{
            "top": "small",
            'left': 'xsmall',
          }}
          pad={{
            "top": "xxsmall",
            'left': "xxsmall",
          }}>
          <Heading level='3'>Homeworks</Heading>
          {
            fetching ? <Spinner /> :
              <Box
                width='large'
              >
                {
                  !!homeworks && homeworks.length > 0 ?
                    <List
                      data={homeworks}
                      pad='xsmall'
                      border='false'
                      width='100%'

                    >
                      {(eachHomework, index) => (
                        <HomeworkTile homeworkData={eachHomework} />
                      )

                      }


                    </List> : <Text>No Homeworks posted by school yet</Text>
                }

              </Box>
          }


        </Box>
      </Box>

    </Layout>
  );
}

/**
 * Main controller for the routing pages 
 * We used private rout to ensure that only athenticated user 
 * only can move through pages otherwise they will be signed out and 
 * routed back to login page. 
 */

import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthContext } from "../context";
import { PrivateRoute } from "./privateRouter";
import { LoginPage, WelcomePage, HomePage, LiveClassesPage, LessonsPage, HomeworkPage, ExamsPage } from '../../Views/Pages';


const NoComponent = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
      <h2>Sorry! This feature is not available now</h2>
    </div>
  )
}

const AppRouter = () => {
  const { loading } = useContext(AuthContext);
  //console.log('loading is ', loading);
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <PrivateRoute exact path="/Welcome" component={WelcomePage} />
        <PrivateRoute exact path="/Home" component={HomePage} />
        <PrivateRoute exact path="/LiveClass" component={LiveClassesPage} />
        <PrivateRoute exact path="/Lessons" component={LessonsPage} />
        <PrivateRoute exact path="/Homework" component={HomeworkPage} />
        <PrivateRoute exact path="/Schedule" component={HomePage} />
        <PrivateRoute exact path="/Exams" component={ExamsPage} />

        {/* <Route exact path="/Signup" component={SignupPage} />
            <Route exact path="/ForgetPassword" component={ForgetPasswordPage} /> */}
        <Route component={NoComponent} />
      </Switch>

    </Router>
  );
}

export default AppRouter;
import React from 'react';
import { NavBar } from './navBar';
import { SideBar } from './sideBar';
import { Box, Grid, Main } from 'grommet';

export const Layout = (props) => {
  const { children } = props;

  return (
    <Grid
      rows={['64px', 'large']}
      columns={['20%', '80%']}
      areas={[
        ['header', 'header'],
        ['sidebar', 'main'],
      ]}
      gap={{ "row": "0px", "column": "0px" }}
    >
      <Box gridArea="header">
        <NavBar />
      </Box>

      <Box background="light-5" gridArea="sidebar">
        <SideBar />
      </Box>

      <Box background="light-2" gridArea="main">
        <Main>
          {children}
        </Main>

      </Box>
    </Grid>
  );
}
import React, { useContext } from 'react';
import { Box, Button, } from 'grommet';
import { Close } from 'grommet-icons';
import { Jutsu } from 'react-jutsu';
import { DBContext } from '../../../Controller/context';
import { Spinner } from '../index';

export const LiveClassFullScreenLayer = (props) => {
  const { close, vClassData } = props;
  const { VCLASS_URL, selectedStudent } = useContext(DBContext);

  const endLiveClass = () => {
    console.log('endLiveClass');
    return close();
  }
  return (
    <Box
      fill
      background="light-4"
      align="center"
      justify="center"
    >
      <Box
        direction='column'
        height='100%'
        width='100%'
      >
        <Button
          icon={<Close />}
          onClick={close}
        >
          Close
        </Button>
        <Box
          justify='center'
          align='center'
          width='100%'

        >

          <Jutsu
            subject={vClassData.title}
            domain={VCLASS_URL}
            roomName={vClassData.roomId}
            displayName={selectedStudent.displayName}
            onMeetingEnd={endLiveClass}
            loadingComponent={<p>ʕ •ᴥ•ʔ Conference is loading ...</p>}
            onload={console.log('video loded')}
            containerStyles={{ width: '720px', height: '480px' }}
          />
        </Box>


      </Box>
    </Box>
  );
}


import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { AuthContext } from '../../Controller/context';
import { Box, Text, } from "grommet";

export const LoginPage = () => {
  const { auth, currentUser } = useContext(AuthContext);
  const history = useHistory();
  // For configuring the Login process by the Firebase UI
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /Welcome after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/Welcome',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      //"phone",
      {
        provider: "phone",
        defaultCountry: 'IN',
      }
    ],

  };

  useEffect(() => {
    if (!!currentUser) {
      history.push('/Welcome');
    }
  })


  return (
    <Box fill background="neutral-2" >
      <Box
        flex
        align="center"
        height='100%'
        justify='center'
        margin='small'
        responsive='true'

      >
        <Box direction='column'
          gap="small"
          overflow={{ horizontal: 'hidden' }}
          align="center"
          justify='center'
        >
          <Box pad='small'>
            <Text size='xlarge' weight='bold'>Signin</Text>
          </Box>
          <Box width='medium'
            elevation='small'
            align='center'
            justify='center'>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
          </Box>


        </Box>
      </Box >

    </Box>


  );


}

import React, { useState, useContext } from "react";
import { DBContext } from '../../Controller/context';
import { Layout } from '../Layout';
import { Box, Button, Text, Image } from 'grommet';


export const HomePage = () => {
  const { selectedStudent } = useContext(DBContext);
  return (
    <Layout>
      <Box fill>
        <Box
          align='center'
          justify='center'
        >
          Welcome {selectedStudent.displayName}

        </Box>

      </Box>

    </Layout>
  );
}

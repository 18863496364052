import React, { useContext, useState } from 'react';
import { Box, Button, Text, Video } from 'grommet';
import { Close } from 'grommet-icons';
import { DBContext } from '../../../Controller/context';
import { Spinner } from '../index';
import { Document, Page } from 'react-pdf';
import { PREVENT_COURS_URL } from '../../../const';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var moment = require('moment');

export const LessonFullScreenLayer = (props) => {
  const { close, lessonData } = props;
  const { selectedStudent } = useContext(DBContext);


  const closeLessonView = () => {
    console.log('closeLessonView');
    return close();
  }



  const PDFComponent = (props) => {
    const { lesson } = props;

    console.log(`${PREVENT_COURS_URL + lesson.lessonUrl}`);

    return (
      <Box
        full
        direction='column'
        align='start'
        height='100%'
        width='100%'
      >
        <object data={lesson.lessonUrl} type="application/pdf" width="100%" height="100%">
          Lesson File: <a href={lesson.lessonUrl}>lesson.pdf</a>
        </object>
      </Box>


    );
  }

  const VideoComponent = (props) => {
    const { lesson } = props;


    return (
      <Box>
        <Video
          controls='below'
          fit="cover"
          autoPlay={true}

        >
          <source key="video" src={lesson.lessonUrl} type="video/mp4" />

        </Video>
      </Box>
    );
  }


  return (
    <Box
      fill
      background="light-4"
      align="center"
      justify="center"
    >
      <Box
        direction='column'
        height='100%'
        width='100%'
      >
        <Button
          icon={<Close />}
          onClick={closeLessonView}
        />
        <Box
          direction='column'
          align='start'
          alignContent='start'
          width='100%'
          height='100%'
          pad='small'

        >
          {/* <Box direction='column'
            align='start'
            margin='small'
            pad='small'
          >
            <Text textAlign='start'
              size='large'>{lessonData.lessonTitle}</Text>
            <Text
              textAlign='start'
              size='medium'
              color='grey'>{lessonData.lessonDiscreption}</Text>
            <Text
              textAlign='start'
              size='xsmall' color='grey'>{lessonData.selectedSubject}</Text>
            <Text
              textAlign='start'
              size='xsmall' color='grey'>{moment(lessonData.createdAt).fromNow()}</Text>
          </Box> */}


          {!lessonData || !lessonData.lessonUrl ? <Text>Error loading lesson. Please try again later</Text> :

            lessonData.type === 'pdf' ? <PDFComponent lesson={lessonData} /> : <VideoComponent lesson={lessonData} />

          }
        </Box>


      </Box>
    </Box>
  );
}


//Model for Student

export class Student {

  constructor(studentId, profileData) {
    this.JSONData = { studentId, profileData }
    this.id = studentId;
    this.firstName = profileData.firstName;
    this.lastName = profileData.lastName;
    this.imageUrl = profileData.imageUrl;
    this.gender = profileData.gender;
    this.isKid = profileData.isKid;
    this.emailId = profileData.emailId;
    this.displayName = `${profileData.firstName} ${profileData.lastName}`
    this.address = profileData.address;
    this.studentClass = profileData.studentClass;
    this.city = profileData.city;
    this.landmark = profileData.landmark;
    this.district = profileData.district;
    this.state = profileData.state;
    this.country = profileData.country;
    this.pincode = profileData.pincode;
    this.motherPhoneNumber = profileData.motherPhoneNumber;
    this.fatherPhoneNumber = profileData.fatherPhoneNumber;
    this.motherName = profileData.motherName;
    this.fatherName = profileData.fatherName;
    this.isTransportBySchool = profileData.isTransportBySchool;
    this.dob = profileData.dob;
    this.schoolData = profileData.schoolData;
    this.courses = profileData.courses;
    this.schoolId = profileData.schoolId;
  }

  getJSONData() {
    return
  }



}



import React, { useEffect, useState, useContext } from 'react';
import firebase from '../firebase';
import { AuthContext } from './authContext';
import { usePersistedState } from '../hooks';
import { Student, LiveClass, Lesson, Homework, HomeworkReview } from '../../Models';
//import { v1 as uuidv1 } from 'uuid';

export const DBContext = React.createContext();

export const DBContextProvider = ({ children }) => {

  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [students, setStudents] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [liveClasses, setLiveClasses] = useState(null);
  const [lessons, setLessons] = useState(null);
  const [homeworks, setHomeworks] = useState(null);

  // Firebase Collection Refrence
  //Firestore
  const db = firebase.firestore();
  //Storage
  const storage = firebase.storage();
  // USER PROFLE
  const UserProfileRef = db.collection('USER_PROFILE');
  // KIDS PROFILE
  const KidsProfileRef = db.collection('KIDS_PROFILE');
  // T&C
  const TermsAndConditionsRef =
    db.collection('TERMS_AND_CONDITIONS');
  // COURSES/PROGRAMS
  const CoursesAndProgramsRef =
    db.collection('STUDENT_PROGRAMS_COURSES');
  // PARENTS AND KIDS
  const ParentsRef = db.collection('PARENTS');
  const StudentsRef = db.collection('STUDENTS');
  // HOMEWORK
  const HomeworkRef = db.collection('STUDENTS_HOMEWORKS');
  const HomeworkAnswersRef =
    db.collection('STUDENTS_HOMEWORK_ANSWERS');

  // PARENT PROFILE
  const ParentsProfileRef = db.collection('PARENTSPROFILE');
  // FEED
  const dbFeedRef = db.collection('STUDENTS_PARENT_FEED');
  // TIMELINE
  const TimeLineRef = db.collection('TIMELINE');
  const TimelinePostLikesRef =
    db.collection('TIMELINEPOSTLIKES');
  const TimelinePostCommentsRef =
    db.collection('TIMELINEPOSTCOMMENTS');
  const TimelinePostSharesRef =
    db.collection('TIMELINEPOSTSHARES');
  // LESSIONS
  const LessonsPostsRef =
    db.collection('STUDENTS_ONLINE_LESSONS');
  const LessonCommentsRef = db.collection('LESSON_COMMENTS');
  const StudentLiveClasses =
    db.collection('STUDENT_LIVE_CLASSES');
  // EXAMS
  const ExamsRef = db.collection('STUDENTS_ONLINE_EXAMS');
  const StudentExamsAnswersRef =
    db.collection('STUDENTS_ONLINE_EXAMS_ANSWERS');
  // TASKS
  const TasksRef = db.collection('STUDENTS_TASKS');

  //Tokens
  const ParentTokensRef = db.collection('PARENTTOKENS');
  // Course Intrests
  const CourseInterestsRef = db.collection('COURSE_INTRESTS');
  // Course Requests
  const CourseRequestsRef = db.collection('COURSE_REQUESTS');

  //For profile security key
  const ProfileSecretRef = db.collection('PROFILE_SECRET_KEYS');

  // FOR HOW TO USE VIDEOS
  const SchoolHowToUseRef = db.collection('SCHOOL-HOW-TO-USE');
  // Firebase Storage Bucket
  // const Storage = firebase.instance;
  // const StudentsHomeworkAnswersStorageRef =
  //   Storage.ref().child('STUDENT_HOMEWORK_ANSWERS');
  // const StudentsExamAnswersStorageRef =
  //   Storage.ref().child('STUDENT_EXAM_ANSWERS');
  // const UsersProfileImagesStorageRef =
  //   Storage.ref().child('USERS_PROFILE_IMAGES');


  /**Live meet url */
  const VCLASS_URL = 'vclass.edaffix.in'
  const VCLASS_APP_URL = 'https://vclass.edaffix.in'

  useEffect(() => {
    if (currentUser != null) {
      console.log(currentUser.phoneNumber);
      console.log(currentUser);
    }
  }, [currentUser]);

  /**
   * Fetch parents data associated with the mobile number
   * used for sign in and save the data in state.
   */
  const fetchStudentsDataAssociatedWithUser = async () => {
    if (!!currentUser) {
      console.log(`getParentData ${currentUser.phoneNumber}`);
      setFetching(true);
      // query for the kids ids associated with
      // user phone number
      const parentSnapShot = await ParentsRef
        .where(currentUser.phoneNumber, '==', true)
        .get();
      // IF no kids are present the main student
      if (parentSnapShot.empty) {
        setFetching(false);
        return;
      } else {
        setFetching(false);
        let studentsUids = [];
        await parentSnapShot.forEach((doc) => studentsUids.push(doc.id));
        console.log(`Kids ids: ${studentsUids}`);
        await fetchStudentsProfile(studentsUids)

      }
    }
  }

  /**
   * Fetch kids data associated with the parents uid
   * and update the data associated with the uid.
   */
  const fetchStudentsProfile = async (studentUIDs) => {
    if (!studentUIDs || studentUIDs.length < 1) {
      console.log('NO STUDENTS FOUND');
      return;
    }
    setFetching(true);
    let students = [];
    for (var i = 0; i < studentUIDs.length; i++) {
      const snapShot = await StudentsRef.doc(studentUIDs[i]).get();
      if (snapShot.exists) {
        const student = new Student(studentUIDs[i], snapShot.data());
        //console.log('Student', student);
        students.push(student);
      }
    }
    console.log('students', students);
    setFetching(false);
    setStudents(students);

  }

  /**
   * This function updates the selected student 
   */
  const updateSelectedStudent = (student) => {
    setSelectedStudent(student);
  }

  /**
   * Fetch Live classes
   * NOTE: now we are only fetching live classes
   * After this we will change it to date range
   */

  const fetchLiveClasses = async () => {
    const now = new Date();
    const lastMidNight = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    console.log('dates', now, lastMidNight);
    // We are querying all documents from the 12AM 
    // upto the present time so that the users can rejoin the 
    // same class if it got disconnected. 
    try {

      const liveQuery = StudentLiveClasses
        .doc(selectedStudent.id)
        .collection('liveClasses')
        .where('createdAt', '>=', lastMidNight)
        .orderBy('createdAt', 'desc');
      setFetching(true);
      const querySnapShot = await liveQuery.get();
      // If no live classees are present in the DB
      // it will set the state an empty array and 
      // it will return.
      setFetching(false);
      if (querySnapShot.empty) {
        console.log('no live classes');
        setLiveClasses([])
        return
      }

      console.log(' live Present');
      // If some documents are there 
      // it will process the data.
      let liveClassesList = [];
      querySnapShot.forEach((eachLiveClass) => {
        const live = new LiveClass(eachLiveClass.id, eachLiveClass.data());
        console.log('live', live);
        liveClassesList.push(live);
      })
      console.log('liveClasses', liveClassesList);
      setLiveClasses([...liveClassesList]);
    } catch (error) {
      setFetching(false);
      setLiveClasses([])
    }
  }

  // /fetc lesson data
  const fetchLessons = async () => {
    setFetching(true);

    try {
      const lessonsRef = LessonsPostsRef
        .doc(selectedStudent.id)
        .collection('onlineLessons')
        .orderBy('createdAt', 'desc')
        .limit(10);

      const lessonsSnapShot = await lessonsRef.get();
      if (lessonsSnapShot.empty) {
        setFetching(false);
        return;
      }
      setFetching(false);
      console.log('lessons');
      // If some documents are there 
      // it will process the data.
      let lessonsList = [];
      lessonsSnapShot.forEach((eachLesson) => {
        const lesson = new Lesson(eachLesson.id, eachLesson.data());
        console.log('eachLesson', lesson);
        lessonsList.push(lesson);
      });
      console.log('lessonList', lessonsList);
      setLessons([...lessonsList]);

    } catch (error) {
      console.log('fetchLessons', error);
      setFetching(false);
    }
  }

  // Fetch homework data and process the data.
  const fetchHomeworks = async () => {
    setFetching(true);

    try {
      const homeworkRef = HomeworkRef
        .doc(selectedStudent.id)
        .collection('homeworks')
        .where('dueDate', '>=', new Date())
        .orderBy('dueDate', 'desc');

      const homeworkSnapShot = await homeworkRef.get();
      if (homeworkSnapShot.empty) {
        setFetching(false);
        setHomeworks([]);
        return;
      }
      setFetching(false);
      console.log('homeworks');
      // If some documents are there 
      // it will process the data.
      let homeworkList = [];
      homeworkSnapShot.forEach((eachHomework) => {
        const homework = new Homework(eachHomework.id, eachHomework.data());
        console.log('homework', homework);
        homeworkList.push(homework);
      });
      console.log('homeworkList', homeworkList);
      setHomeworks([...homeworkList]);

    } catch (error) {
      console.log('fetchhomework', error);
      setFetching(false);
      setHomeworks([]);
    }
  }


  return (
    <DBContext.Provider
      value={{
        userData,
        fetching,
        currentUser,
        students,
        selectedStudent,
        // Live classes
        liveClasses,
        VCLASS_URL,
        VCLASS_APP_URL,
        // Lessons
        lessons,
        // Homework
        homeworks,
        //Functions
        fetchStudentsDataAssociatedWithUser,
        updateSelectedStudent,
        //Live classes
        fetchLiveClasses,
        //Lessons
        fetchLessons,
        // Homework
        fetchHomeworks,
      }}
    >
      {children}
    </DBContext.Provider>
  )

}
import React from 'react';
import { Layout } from '../Layout';

export const ExamsPage = () => {
  return (
    <Layout>
      <h1>ExamsPage</h1>
    </Layout>

  );
}


import React, { useEffect, useContext, useState } from 'react';
import { Layout } from '../Layout';
import { DBContext } from '../../Controller/context';
import { Spinner } from '../Components';
import {
  Box, Heading, Text, Card,
  CardBody, List, Button, Icons, Avatar, Layer
} from "grommet";
import { DocumentVideo } from 'grommet-icons';
import { LiveClassFullScreenLayer } from '../Components';
var moment = require('moment');

//import { LiveClassMockData } from '../../test_data';

export const LiveClassesPage = () => {
  const {
    fetching,
    liveClasses,
    fetchLiveClasses
  } = useContext(DBContext);

  const [isFullScreenLiveClassOpen, setIsFullScreenLiveClassOpen] = useState(false);
  const [selectedLiveClass, setSelectedLiveClass] = useState(null);
  // it fetches the live classes from the db 
  // when the page first loaded, and simultaniously 
  // whenever the liveclasses data changed.
  useEffect(() => {
    fetchLiveClasses();
  }, []);

  /**
   * Updates the selected live class in the state
   * Open/Close the live class full screen model
   */
  const setOpen = (vclass) => {
    setSelectedLiveClass(vclass);
    setIsFullScreenLiveClassOpen(true);
  }
  const setClose = () => {
    //setSelectedLiveClass(null);
    console.log('should close the live class');
    return setIsFullScreenLiveClassOpen(false);
  }
  return (
    <Layout>
      <Box fill>
        {
          isFullScreenLiveClassOpen &&
          <Layer full animation="fadeIn">

            <LiveClassFullScreenLayer vClassData={selectedLiveClass} close={setClose} />
          </Layer>

        }

        {fetching ? <Spinner /> :
          <Box
            direction='column'
            align='center'
            basis='full'
            margin={{
              "top": "small",
            }}
            pad={{
              "top": "xxsmall",
              'left': "xxsmall",
            }}>
            <Heading level='3'>Live Classes</Heading>
            {
              !!liveClasses && liveClasses.length > 0 ?
                <List
                  data={liveClasses}
                  pad="xsmall"
                  border='false'

                >
                  {(eachClassData, index) => (
                    <Box
                      key={index}
                      direction="row-responsive"
                      gap="large"
                      align='stretch'
                      width='100%'
                      height='100%'
                      margin={{ horizontal: 'small' }}
                      pad='small'
                    >
                      <DocumentVideo size="large" />
                      <Box
                        direction='column'
                        align='start'
                      >
                        <Text weight="bold">{eachClassData.title}</Text>
                        <Text>{eachClassData.selectedSubject}</Text>
                        <Text size='xsmall'>{`Started ${moment(eachClassData.createdAt).fromNow()}`}</Text>
                      </Box>

                      <Button primary disabled={eachClassData.status === 'FINISHED'} label="Join" onClick={() => setOpen(eachClassData)} />
                    </Box>

                  )}
                </List>
                :
                <Box>
                  <Text>No Classes</Text>
                </Box>
            }


          </Box>
        }
      </Box>

    </Layout >

  );
}

import React from 'react';
import { Grommet } from 'grommet';
import HomeRouter from './Controller/router';
import { AuthProvider } from "./Controller/context/authContext";
import { DBContextProvider } from './Controller/context/dbContext';
import { grommet } from 'grommet/themes';
const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

function App() {
  return (
    <Grommet full theme={grommet}>
      <AuthProvider>
        <DBContextProvider>
          <HomeRouter />
        </DBContextProvider>
      </AuthProvider>

    </Grommet>

  );
}

export default App;


export class Lesson {

  constructor(id, data) {
    this.id = id;
    this.createdAt = data.createdAt.toDate();
    this.selectedSubject = data.selectedSubject;
    this.schoolData = data.schoolData;
    this.videoDiscreption = data.videoDiscreption;
    this.videoTitle = data.videoTitle;
    this.videoUrl = data.videoUrl;
    this.programId = data.programId;
    this.programType = data.programType;
    this.studentId = data.studentId;
    this.type = data.type;
    this.lessonUrl = data.lessonUrl ? data.lessonUrl : data.videoUrl;
    this.lessonTitle = data.lessonTitle ? data.lessonTitle : data.videoTitle;
    this.lessonDiscreption = data.lessonDiscreption ? data.lessonDiscreption : data.videoDiscreption;
  }
};
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Text, Card, List, Button, Icons, Avatar } from "grommet";
import { ShareOption, LinkNext } from 'grommet-icons';
import { DBContext } from '../../Controller/context';
import Spinner from '../Components/Spinner/SpinnerBrand';

export const WelcomePage = () => {
  const {
    fetchStudentsDataAssociatedWithUser,
    currentUser,
    loading,
    fetching,
    students,
    updateSelectedStudent,
  } = useContext(DBContext);
  const history = useHistory();

  // Checks weather the curee
  useEffect(() => {

    if (!!currentUser) {
      initializeStudentdsList();
    }

  }, [currentUser]);

  const initializeStudentdsList = async () => {
    await fetchStudentsDataAssociatedWithUser();
  }

  const handleClickOnStudentTile = async (student) => {
    //update selectedstudent in the context
    updateSelectedStudent(student);
    //move to the HOME page.
    return await history.push('/Home');
  }

  // If current user is not renderdyet, we will use 
  // loading scren to display progress

  const StudentListCard = (props) => {
    const { studentData } = props;
    const history = useHistory();
    return (
      <Box margin='small' pad='small'>

        {!!studentData && studentData.length > 0 ?
          <List
            data={studentData}
            pad='medium'
            onClickItem={event => handleClickOnStudentTile(event.item)}
          >
            {(eachStudent, index) => (
              <Box key={index}
                direction="row-responsive"
                gap="large"
                size="xsmall"
                align="center">
                <Avatar src={eachStudent.imageUrl} />
                <Text>{`${eachStudent.firstName} ${eachStudent.lastName}`}</Text>
                <LinkNext size='medium' />

              </Box>
            )}
          </List>
          :
          <Text>No Studens Added</Text>

        }
      </Box>




    )
  }

  return (
    <Box fill background="neutral-2" >

      <Box
        direction='column'
        justify='center'
        align='center'
        basis='medium'
        margin={{
          "top": "large",
        }}
        pad={{
          "top": "large",
        }}
      >
        {!currentUser || fetching || loading ? <Spinner /> :
          <Box>
            <Text
              size='large'
              textAlign='center'
              weight='bold'
            >
              Welcome {!!currentUser.displayName && currentUser.displayName.toUpperCase()}
            </Text>
            <StudentListCard studentData={students} />
          </Box>
        }
      </Box>

    </Box>
  );
}

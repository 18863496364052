import React, { useContext, useState } from 'react';
import { Box, Button, Text, Image } from 'grommet';
import { Close } from 'grommet-icons';
import { DBContext } from '../../../Controller/context';
import { Spinner } from '../index';
import { Document, Page } from 'react-pdf';
import { PREVENT_COURS_URL } from '../../../const';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var moment = require('moment');

export const HomeworkFullScreenLayer = (props) => {
  const { close, homeworkData } = props;
  //const { selectedStudent } = useContext(DBContext);

  //console.log('homeworkData', homeworkData);
  const closeView = () => {
    console.log('closeHomeworkView');
    return close();
  }

  const ImageOrPDFComponent = (props) => {
    const { homework } = props;

    // console.log(`${PREVENT_COURS_URL + homework.fileUrl}`);
    //const file = (`${PREVENT_COURS_URL + homework.fileUrl}`);
    if (homework.fileType === 'pdf') {
      return (
        <Box
          full
          align='start'
          height='100%'
          width='100%'
        >
          <Box
            height='100%'
            width='100%'>
            <object
              data={homework.fileUrl}
              type="application/pdf"
              width="90%"
              height="90%">
              Error loading HomeWork
            </object>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          full
          direction='column'
          align='start'
          height='100%'
          width='100%'
        >
          <Image
            fill
            fit="cover"
            src={homework.fileUrl}
          />
        </Box>
      );
    }
  }

  const TextComponent = (props) => {
    const { homework } = props;
    return (
      <Box
        full
        direction='column'
        align='center'
        height='100%'
        width='100%'
      >

        <Text size='large'>{homework.homeworkTitle}</Text>

        <Box
          direction='row-responsive'
          justify='start'
          align='center'
          gap='large'
          margin='xsmall'
        >
          <Text size='xsmall' color='grey'>{homeworkData.selectedSubject}</Text>
          <Text size='xsmall' color='grey'>{`Due in ${moment(homeworkData.dueDate).fromNow()}`}</Text>
        </Box>

        <Text size='medium' color='grey'>{homework.homeworkDiscreption}</Text>




      </Box>
    );
  }

  return (
    <Box
      fill
      background="light-4"
      align="center"
      justify="center"
    >
      <Box
        direction='column'
        height='100%'
        width='100%'
      >
        <Button
          icon={<Close />}
          onClick={closeView}
        />
        <Box
          direction='column'
          align='start'
          alignContent='start'
          width='100%'
          height='100%'
          pad='small'

        >


          {!homeworkData ? <Text>Error loading Homework. Please try again later</Text> :

            homeworkData.fileType !== '' ? <ImageOrPDFComponent homework={homeworkData} /> : <TextComponent homework={homeworkData} />

          }
        </Box>


      </Box>
    </Box>
  );
}


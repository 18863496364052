import React, { useContext } from 'react';
import { DBContext } from '../../Controller/context';
import { Text, Box, Header, Menu, Avatar } from 'grommet';

export const NavBar = () => {
  const { selectedStudent, students, updateSelectedStudent } = useContext(DBContext);
  const handleChangeSelectedStudent = (selectedStudent) => {
    updateSelectedStudent(selectedStudent);
  }
  return (
    <Header
      background="brand" pad="xsmall" height='64px'>
      <Box
        direction="row"
        align="center"
        gap="small"
        pad="small"
      >
        <Text> {(!!selectedStudent && !!selectedStudent.schoolData) ? selectedStudent.schoolData.schoolName : 'Edaffix'} </Text>
      </Box>
      <Menu label={!!selectedStudent.displayName ? selectedStudent.displayName : selectedStudent.firstName} items={
        students.map(eachStudent => (
          {
            label: <Box alignSelf="center" >{`${eachStudent.displayName}`}</Box>,
            onClick: () => handleChangeSelectedStudent(eachStudent),
            icon: (
              <Box pad="xsmall">
                <Avatar src={!!eachStudent.imageUrl ? eachStudent.imageUrl : ''} />
              </Box>
            ),
          }
        ))
      } />
    </Header>
  );
}
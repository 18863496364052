export class LiveClass {

  constructor(id, data) {
    this.id = id;
    this.title = data.title;
    this.baseUrl = data.baseUrl;
    this.selectedSubject = data.selectedSubject;
    this.status = data.status;
    this.createdAt = data.createdAt.toDate();
    this.endedAt = data.endedAt;
    this.roomId = data.roomId;
    this.schoolData = data.schoolData;
    this.programId = data.programId;
    this.programType = data.programType;
    this.studentId = data.studentId;
  }
};
import firebase from 'firebase';

//Your learn.edaffix.com web app's Firebase configuration- NEW
const firebaseConfig = {
    apiKey: "AIzaSyBebfefbJjMIszzltT3uPlaruOLV48FDDg",
    authDomain: "edaffix-c92fe.firebaseapp.com",
    databaseURL: "https://edaffix-c92fe.firebaseio.com",
    projectId: "edaffix-c92fe",
    storageBucket: "edaffix-c92fe.appspot.com",
    messagingSenderId: "850649843717",
    appId: "1:850649843717:web:f959b66c0e63f38a12f46c",
    measurementId: "G-4E3NH42RG1"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
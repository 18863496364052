import React, { useEffect, useContext, useState } from 'react';
import { Layout } from '../Layout';
import { DBContext } from '../../Controller/context';
import { Spinner, LessonFullScreenLayer } from '../Components';
import {
  Box, Heading, Text, Layer,
  List, Button,
} from "grommet";
import { CirclePlay, DocumentPdf } from 'grommet-icons';
var moment = require('moment');

export const LessonsPage = () => {
  const {
    fetching,
    lessons,
    fetchLessons,

  } = useContext(DBContext);

  const [isLessonLayerOpen, setIsLessonLayerOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(false);

  /**
   * Fetches the lessons from the cloud when
   * the page first loaded
   */
  useEffect(() => {
    fetchLessons();
  }, [])

  // Lesson Tile component to show Lesson Tile
  const LessonTile = (props) => {
    const { lessonData } = props;
    return (
      <Box
        fill
        key={lessonData.id}
        direction='row-responsive'
        justify='stretch'
        align='center'
        alignContent='stretch'
        round='true'
        overflow={{ horizontal: true }}
        width='100%'

      >
        {
          !!lessonData.type && lessonData.type === 'pdf' ?
            <DocumentPdf size='large' /> :
            <CirclePlay size='large' />
        }


        <Box
          direction='column'
          pad='small'
          justify='evenly'
          flex='grow'
        >
          <Text size='large'>{lessonData.lessonTitle}</Text>
          <Text size='medium' color='grey'>{lessonData.lessonDiscreption}</Text>
          <Box
            direction='row-responsive'
            justify='start'
            align='center'
            gap='large'
            margin='xsmall'
          >
            <Text size='xsmall' color='grey'>{lessonData.selectedSubject}</Text>
            <Text size='xsmall' color='grey'>{moment(lessonData.createdAt).fromNow()}</Text>
          </Box>


        </Box>
        <Button
          primary
          size='small'
          label='View Lesson'
          onClick={() => openLessonLayer(lessonData)}
        />

      </Box>
    )
  }


  /**
   * For opening and closing of lesson view
   * @param {Object} selectedLesson 
   */
  const openLessonLayer = (lessonData) => {
    setSelectedLesson(lessonData)
    setIsLessonLayerOpen(true)
  }

  const closeLessonLayer = () => {
    setIsLessonLayerOpen(false)
  }


  return (
    <Layout>
      <Box fill>
        {isLessonLayerOpen &&
          <Layer full animation="fadeIn">
            <LessonFullScreenLayer
              close={closeLessonLayer}
              lessonData={selectedLesson}
            />
          </Layer>
        }

        <Box
          direction='column'
          align='start'
          basis='full'
          margin={{
            "top": "small",
            'left': 'xsmall',
          }}
          pad={{
            "top": "xxsmall",
            'left': "xxsmall",
          }}>
          <Heading level='3'>Lessons</Heading>
          {
            fetching ? <Spinner /> :
              <Box
                width='large'
              >
                {
                  !!lessons && lessons.length > 0 ?
                    <List
                      data={lessons}
                      pad='xsmall'
                      border='false'
                      width='100%'

                    >
                      {(eachLesson, index) => (
                        <LessonTile lessonData={eachLesson} />
                      )

                      }


                    </List> : <Text>No lessons posted by school yet</Text>
                }

              </Box>
          }


        </Box>
      </Box>
    </Layout >

  );
}

import React, { useEffect, useState } from 'react';
import firebase from '../firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const auth = firebase.auth();

  const doSignOut = () => auth.signOut();

  useEffect(() => {
    setLoading(true)
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
      user && console.log(user.displayName)
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        auth,
        doSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
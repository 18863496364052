export class Homework {

  constructor(id, data) {
    this.id = id
    this.studentId = data.studentId;
    this.homeworkDiscreption = data.homeworkDiscreption;
    this.homeworkTitle = data.homeworkTitle;
    this.schoolId = data.schoolId;
    this.schoolName = data.schoolName;
    this.schoolImageUrl = data.schoolImageUrl;
    this.selectedSubject = data.selectedSubject;
    this.seen = data.seen;
    this.homeworkDone = data.homeworkDone;
    this.dueDate = data.dueDate.toDate();
    this.createdAt = data.createdAt.toDate();
    this.programId = data.programId;
    this.programType = data.programType;
    this.schoolData = data.schoolData;
    //NEW
    this.fileType = !!data.fileType ? data.fileType : '';
    this.fileUrl = !!data.fileUrl ? data.fileUrl : '';
  }
}